import React from "react";
import styled from "styled-components";
import Text from "../_global/editorial/Text";
import Section from "../_global/layout/Section";
import Spacer from "../_global/layout/Spacer";
import Link from "../_global/localization/Link";

const Comp = styled(Section)`

`;

const UList = styled.ul`
  list-style: disc;
  padding-left: 1rem;
`;

const LinkText = styled(Text)`
  width: fit-content;
`;

const TheLink = styled(Link)`
  text-decoration: underline;

  :hover {
    text-decoration: none;
  }
`;

const PressData = (props) => {
	const { data } = props;
	// console.log(data);
	const sections = data.map((item, index) => {
		const links = item.items.map((item, index) => {
			return (
				<li key={index}>
					<LinkText s="medium">
						<TheLink to={item.link ? item.link : item.file ? item.file.asset.url : null}>
							{item.title}
						</TheLink>
						{item.source ? <> - {item.source}</> : null}
					</LinkText>
				</li>
			);
		});

		if (item.items.length > 0) {
			return (
				<React.Fragment key={index}>
					<Text s="h2" as="h2">
						{item.title}
					</Text>
					<Spacer s="small" />

					<UList>
						{links}
					</UList>
					<Spacer s="large" />
					<Spacer s="small" />
				</React.Fragment>
			);
		} else return null;
	});

	return (
		<Comp m="medium">
			{sections}
		</Comp>
	);
};

export default PressData;
