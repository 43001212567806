import React from 'react';
import localize from '../components/_global/localization/localize';
import { graphql } from 'gatsby';
import Layout from '../components/_global/layout/Layout';
import PageHeader from '../components/_global/layout/PageHeader';
import Section from '../components/_global/layout/Section';
import Spacer from '../components/_global/layout/Spacer';
import Block from '../components/_global/editorial/Block';
import PressData from '../components/4_foundation/PressData';

const Press = ({ data }) => {
    const { page } = data;

    return (
        <Layout title={page.common.title}>
            <PageHeader title={page.common.title} />


            <Section m="medium">
                <Block data={page.text} />
                <Spacer s="large" />
            </Section>

            <PressData data={page.press} />

            <Spacer s="large" />
        </Layout>
    );
};

export default localize(Press);

export const query = graphql`
    {
        page: sanityPress {
            common {
                link
                title {
                    _type
                    fr
                }
            }
            text {
                ...RegularBlockFragment
            }
            press {
                items {
                    ... on SanityPressFile {
                        title {
                            _type
                            fr
                        }
                        file {
                            asset {
                                url
                            }
                        }
                        source
                    }
                    ... on SanityPressLink {
                        title {
                            _type
                            fr
                        }
                        link
                        source
                    }
                }
                title {
                  _type
                  fr
                }
            }
        }
    }
`;